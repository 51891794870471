<template>
	<div class="page_content">
		<div class="page_detail">
			<a-divider orientation="left">页面标题</a-divider>
			<a-card>
					<!-- 默认关于润田 10个字符 -->
					<a-input v-model="page_title"  maxLength='10' placeholder="请输入页面标题"
						class='banner_input' />
			</a-card>
			<a-divider orientation="left">轮播广告图</a-divider>
			<div style='height: 24px;'></div>
			<a-card :title="'图片数量 '+banner_list.length+ '/10'">
				<a-switch slot="extra" @change='onChangeSwitch' checked-children="启动" un-checked-children="禁用"
					default-checked />
				<!-- <a-switch  default-checked @change="onChange" /> -->
				<!-- <a slot="extra" href="#" @click='get_show'>启动/禁用</a> -->
				<a-card v-for='(item,index) in banner_list'>
					<div class="clearfix clearfix_banner">
						<div>
							<a-upload @change="handleChangeImg($event,index)" :customRequest="upload"
								list-type="picture-card" :file-list="item.url_list" @preview="handlePreview">
								<div v-if='item.url_list.length<1'>
									<a-icon type="plus" />
									<div class="ant-upload-text">
										上传
									</div>
								</div>
							</a-upload>
						</div>
						<div class='banner_info'>
							<p>
								<span>跳转类型:</span>
								<a-radio-group v-model:value="item.config.type">
									<template>
										<a-radio value="H5">网页</a-radio>
										<a-radio value="applet">小程序</a-radio>
									</template>
								</a-radio-group>
							</p>
							<p>
								<span>跳转链接:</span><a-input v-model="item.config.url" placeholder="请输入需要跳转地址"
									class='banner_input' />
							</p>
							<p v-if="item.config.type=='applet'">
								<span>Appkey:</span> <a-input v-model="item.config.appkey" placeholder="请输入小程序的原始ID"
									class='banner_input' />
							</p>
							<p v-if="item.config.type=='applet'">
								<span>AppId:</span> <a-input v-model="item.config.appid" placeholder="请输入小程序的AppId"
									class='banner_input' />
							</p>

						</div>
						<div class='del_icon'>
							<a-icon type="delete" @click='del_banner(index)' />
						</div>
						<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
							<img alt="example" style="width: 100%" :src="previewImage" />
						</a-modal>
					</div>
				</a-card>
				<div style='width:100%;text-align:center;margin-top:20px;'>
					<a-button type="primary" @click='add_banner'>
						新增轮播图
					</a-button>
				</div>

			</a-card>
			<a-divider orientation="left">内容编辑</a-divider>
			<div>
				
				<a-card>
						<h3>
							提示: 复制标签自定义至富文本任意位置
						</h3>
							<div style='height: 24px;'></div>
						<p class='text_description'>
							<span
								v-for='(item,index) in tips'>{{item.label}}:{{item.value}}{{index!=tips.length-1?' ; ':''}}</span>
						</p>
				</a-card>
				<div style='height: 24px;'></div>
				<a-card title="富文本内容">
					<a slot="extra" href="#" @click='get_show'>预览</a>
					<Form ref="form" :form_data="form_data"></Form>
				</a-card>
				<div class="box_form box_form_btns">
					<a-button type="primary" @click='save'>保存</a-button>
				</div>
			</div>
			<a-modal title="预览" :visible="visible" @cancel='visible=false' @ok='visible=false'>
				<div>
					<a-carousel arrows>
						<div slot="prevArrow" slot-scope="props" class="custom-slick-arrow"
							style="left: 10px;zIndex: 1">
							<a-icon type="left-circle" />
						</div>
						<div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
							<a-icon type="right-circle" />
						</div>
						<div class='carouselM_2X1' v-for="item in banner_list">
							<img class='img' :src="item.url" alt="">
						</div>
					</a-carousel>
					<div class="ql-snow">
						<div class='html_info ql-editor' v-html='html_info'></div>
					</div>
					<div></div>
				</div>
			</a-modal>
		</div>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import LookImages from "@/components/LookImages";
	import Form from "@/components/Form";
	import FormUpload from "@/components/FormUpload";
	import {
		saveConfig,
		getConfig
	} from '@/api/traceability.js'
	import Avatar from "@/components/small/Avatar";
	export default {
		components: {
			LookImages,
			Avatar,
			Form,
			FormUpload
		},
		data() {
			return {
				tips: [],
				content: '',
				page_title:'',
				checkbox_value: [],
				checkbox_list: [],
				html_info: '',
				visible: false,
				form_data: {
					show_submit_btn: false,
					close_reset_btn: false,
					show_close_btn: false,
					list: [{
							type: "editor",
							name: "template",
							content: '',
							options: {},
							treeData: [],
							multiple: true,
							tips: "--type_name-- : 投放类型"
						},

					]
				},
				previewVisible: false,
				previewImage: '',
				banner_list: [],

			}
		},
		created() {
			this.get_show_info()
		},
		methods: {
			onChangeSwitch(e) {
				this.checkbox_list.forEach(info => {
					if (info.value == "--image--") {
						info.is_check = e ? 1 : -1
					}
				})
			},
			add_banner() {
				this.banner_list.push({
					config: {
						appid: '',
						appkey: '',
						url: '',
						
					},
					url_list: []
				})
			},
			del_banner(index) {
				this.banner_list.splice(index, 1)
			},
			upload(e, item) {
				return new Promise((resolve, reject) => {
					this.$req({
						mode: 'file',
						url: '/manage/api.plugs/upload',
						data: {
							file: e.file,
						}
					}).then(res => {
						e.onSuccess(res.data.upload_data);
						resolve(res);
					}).catch(e => {
						e.onError();
						reject(e)
					})
				})


			},
			get_show_info() {
				this.checkbox_value = []
				this.banner_list = []
				getConfig().then(res => {
					res.data.list.banner.forEach((item, index) => {
						item.url_list = null
						if (item.url) {
							item.url_list = [{
								uid: index,
								name: index,
								status: index,
								url: item.url ? item.url : ''
							}]
						}
						this.banner_list.push(item)
					})
					this.page_title =  res.data.list.page_title
					this.tips = res.data.list.tips
					this.form_data.list[0].content = res.data.list.content
					this.checkbox_list = res.data.list.setting
					this.checkbox_list.forEach(item => {
						if (item.is_check == 1) {
							this.checkbox_value.push(item.value)
						}
					})
				})

			},
			handleCancel() {
				this.previewVisible = false;
			},
			async handlePreview(file) {
				console.log('file', file)
				// if (!file.url && !file.preview) {
				// 	file.preview = await getBase64(file.originFileObj);
				// }
				this.previewImage = file.url || file.response.url;
				this.previewVisible = true;
			},

			onChange() {

			},
			get_info() {

			},
			
			handleChangeImg(e, index) {
				this.banner_list[index].url_list = e ? e.fileList : [];
			},
			save() {
				
				let data = {
					banner: [],
					page_title:this.page_title,
				}
				this.banner_list.forEach(item => {
					let obj = {
						config: item.config,
						url: item.url_list[0].response ? item.url_list[0].response.url : item.url_list[0].url
					}
					data.banner.push(obj)
				})
				
				data.setting = this.checkbox_list
				this.$refs.form.submit().then(res => {
					data.content = res.template
					saveConfig({
						data: {
							...data
						},
						info: true
					}).then(res => {
						this.get_show_info()
					})
				})
			},
			get_show() {
				this.$refs.form.submit().then(res => {
					this.html_info = res.template
					this.visible = true
				})
			},
			release() {

			},
		}
	}
</script>

<style lang="less" scoped>
	.clearfix_banner {
		display: flex;
		margin-bottom: 12px;
		align-items: center;

		.banner_info {
			width: 80%;

			p {
				display: flex;
				align-items: center;
				margin-bottom: 3px;

				span:nth-child(1) {
					width: 80px;
					text-align: right;
					margin-right: 10px;
				}

				.banner_input {
					flex: 1;
				}
			}
		}

		.del_icon {
			width: 50px;
			text-align: right;
		}


	}

	.page_detail {
		margin: 0;
		margin-left: 5%;

	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.carouselM_2X1 {
		width: 375px;
		height: 160px;

		.img {
			display: inline-block;
			width: 100%;
			height: 100%;
		}
	}

	.ant-carousel .slick-slide {
		text-align: center;
		height: 160px;
		line-height: 160px;
		background: #364d79;
		overflow: hidden;
	}

	.ant-carousel .custom-slick-arrow {
		width: 25px;
		height: 25px;
		font-size: 25px;
		color: #fff;
		background-color: rgba(31, 45, 61, 0.11);
		opacity: 0.3;
	}

	.ant-carousel .custom-slick-arrow:before {
		display: none;
	}

	.ant-carousel .custom-slick-arrow:hover {
		opacity: 0.5;
	}

	.ant-carousel .slick-slide h3 {
		color: #fff;
	}

	.html_info {
		p {
			img {
				display: block;
				width: 100%;
			}
		}

	}

	.text_description {
		margin-bottom: 20px;
	}
</style>