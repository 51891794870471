import req from "../methods/req"
import method from '../methods/method'



export const getDeviceList = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/tracing.machine/getList',
        data
    })
}

// 获取仓库下拉
export const getWarehouseSelect = function (data) {
	return req({
		url: '/manage/tracing.machine/getWarehouseSelect',
		...data,
	})
}

// 获取产线下拉
export const getStatusSelect = function (data) {
	return req({
		url: '/manage/tracing.machine/getStatusSelect',
		...data,
	})
}


// 获取状态下拉
export const getLinseSelect = function (data) {
	return req({
		url: '/manage/tracing.machine/getLinseSelect',
		...data,
	})
}




// 保存设备信息
export const save = function (data) {
	return req({
		url: '/manage/tracing.machine/save',
		...data,
	})
}



// 设备列表统计
export const getTopCount = function (data) {
	return req({
		url: '/manage/tracing.machine/getTopCount',
		...data,
	})
}



// 修改设备状态
export const changeStatus = function (data) {
	return req({
		url: '/manage/tracing.machine/changeStatus',
		...data,
	})
}



// 删除设备
export const deleteMachine = function (data) {
	return req({
		url: '/manage/tracing.machine/deleteMachine',
		...data,
	})
}

// 溯源码
export const getTracingList = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/tracing.source_code/getList',
        data
    })
}

// 获取产品配置
export const getStorageGoodsInfo = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/tracing.machine/getStorageGoodsInfo',
        data
    })
}



export const getTracingCount = function (data) {
	return req({
		url: '/manage/tracing.source_code/getTopCount',
		...data,
	})
}

export const TracingSave = function (data) {
	return req({
		url: '/manage/tracing.source_code/save',
		...data,
	})
}

// export const getCodeDetail = function (data) {
// 	return req({
// 		url: '/manage/tracing.source_code/getCodeDetail',
// 		...data,
// 	})
// }
export const getCodeDetail = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
       	url: '/manage/tracing.source_code/getCodeDetail',
        data
    })
}


export const getCodeCount = function (data) {
	return req({
		url: '/manage/tracing.source_code/getCodeCount',
		...data,
	})
}
// 获取经销商
export const getSellerInfoSelect = function (data) {
	return req({
		url: '/manage/crm.seller/getSellerInfoSelect',
		...data,
	})
}

export const getScanCodeLog = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
       	url: '/manage/tracing.source_code/getScanCodeLog',
        data
    })
}

// 模版数据
export const getConfig = function (data) {
	return req({
		url: '/manage/tracing.setting/getConfig',
		...data,
	})
}
export const saveConfig = function (data) {
	return req({
		url: '/manage/tracing.setting/saveConfig',
		...data,
	})
}


 //报表

 
 export const getStatisticsList = function({
     _this,
     data = {}
 }) {
     return method.get_list({
         _this,
        	url: '/manage/tracing.statistics/getStatisticsList',
         data
     })
 }
 export const  getStatisticsTop= function (data) {
 	return req({
 		url: '/manage/tracing.statistics/getStatisticsTop',
 		...data,
 	})
 }
 
 
 
 
 
 
  // //产线
  export const getLineList = function({
      _this,
      data = {}
  }) {
      return method.get_list({
          _this,
         	url: '/manage/tracing.line/getList',
          data
      })
  }
  
export const saveLine = function (data) {
	return req({
		url: '/manage/tracing.line/save',
		...data,
	})
}

export const getLineTopCount = function (data) {
	return req({
		url: '/manage/tracing.line/getTopCount',
		...data,
	})
}
export const changeLineStatus = function (data) {
	return req({
		url: '/manage/tracing.line/changeStatus',
		...data,
	})
}
// 出库单 
export const getOutcomeList = function({
      _this,
      data = {}
  }) {
      return method.get_list({
          _this,
         	url: '/manage/tracing.source_code/getOutcomeList',
          data
      })
  }
  
export const getOutcomeCount = function (data) {
	return req({
		url: '/manage/tracing.source_code/getOutcomeCount',
		...data,
	})
}


// 获取基地

export const getFactorySelect = function (data) {
	return req({
		url: '/manage/tracing.machine/getFactorySelect',
		...data,
	})
}

// 生成图片
export const createCode = function (data) {
	return req({
		url: '/manage/tracing.source_code/createCode',
		...data,
	})
}



// 获取产品名称
export const getDrpGoodsSelect = function (data) {
	return req({
		url: '/manage/crm.goods/getDrpGoodsSelect',
		...data,
	})
}


// 保存产品配置
export const saveStorageGoodsConfig = function (data) {
	return req({
		url: '/manage/tracing.machine/saveStorageGoodsConfig',
		...data,
	})
}


// 删除设备产品配置
export const delStorageGoodsConfig = function (data) {
	return req({
		url: '/manage/tracing.machine/delStorageGoodsConfig',
		...data,
	})
}